<template>
    <div>
      <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
  </template>
  
  <script>
  import { Bar } from "vue-chartjs/legacy";
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    LineController,
    BarController,
  } from "chart.js";
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    LineController,
    BarController
  );
  
  export default {
    name: "MixedChart",
    components: {
      Bar,
    },
    props: {
        chartOptions: {
            type:Object,
        },
        chartData:{
            type:Object,
        },
      chartId: {
        type: String,
        default: "mixed-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 500,
      },
      height: {
        type: Number,
        default: 300,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => ({}),
      },
      plugins: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
      };
    },
  };
  </script>
  
  <style scoped>
  </style>
  